import React, { useState, useEffect, useReducer, useContext } from 'react';
import openSocket from '../../services/socket-io';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import TicketsListSkeleton from '../TicketsListSkeleton';
import useTickets from '../../hooks/useTickets';
import { AuthContext } from '../../context/Auth/AuthContext';
import TicketListItem from '../TicketListItem';
import TicketListService from '../../services/tickets/ticketsListService';
import { Container } from './styles';
import { TicketActionType } from '../../services/tickets/actionType';
import { Message } from '../../core/LabelType';

interface TicketsListProps {
    status: any;
    searchParam: any;
    showAll: any;
    selectedQueueIds: any;
    updateCount: any;
    style: any;
}

export default function TicketsList(props: TicketsListProps): JSX.Element {
    const {
        status,
        searchParam,
        showAll,
        selectedQueueIds,
        updateCount,
        style,
    } = props;
    const [pageNumber, setPageNumber] = useState(1);
    const [ticketsList, dispatch] = useReducer(TicketListService.reducer, []);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        dispatch({
            type: TicketActionType.RESET,
            payload: undefined,
        });
        setPageNumber(1);
    }, [status, searchParam, dispatch, showAll, selectedQueueIds]);

    const { tickets, hasMore, loading } = useTickets({
        searchParam,
        pageNumber,
        status,
        date: undefined,
        showAll,
        queueIds: JSON.stringify(selectedQueueIds),
        withUnreadMessages: undefined,
    });

    useEffect(() => {
        if (!status && !searchParam) return;
        dispatch({
            type: TicketActionType.LOAD_TICKETS,
            payload: tickets,
        });
    }, [searchParam, status, tickets]);

    useEffect(() => {
        const socket = openSocket();

        const shouldUpdateTicket = (ticket: any) =>
            !searchParam &&
            (!ticket.userId || ticket.userId === user?.id || showAll) &&
            (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

        const notBelongsToUserQueues = (ticket: any) =>
            ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

        socket.on('connect', () => {
            if (status) {
                socket.emit('joinTickets', status);
            } else {
                socket.emit('joinNotification');
            }
        });

        socket.on(
            'ticket',
            (data: { action: string; ticketId: any; ticket: { id: any } }) => {
                if (data.action === 'updateUnread') {
                    dispatch({
                        type: TicketActionType.RESET_UNREAD,
                        payload: data.ticketId,
                    });
                }

                if (
                    data.action === 'update' &&
                    shouldUpdateTicket(data.ticket)
                ) {
                    dispatch({
                        type: TicketActionType.UPDATE_TICKET,
                        payload: data.ticket,
                    });
                }

                if (
                    data.action === 'update' &&
                    notBelongsToUserQueues(data.ticket)
                ) {
                    dispatch({
                        type: TicketActionType.DELETE_TICKET,
                        payload: data.ticket.id,
                    });
                }

                if (data.action === 'delete') {
                    dispatch({
                        type: TicketActionType.DELETE_TICKET,
                        payload: data.ticketId,
                    });
                }
            },
        );

        socket.on(
            'appMessage',
            async (data: { action: string; ticket: { queueId: any } }) => {
                if (
                    data.action === 'create' &&
                    shouldUpdateTicket(data.ticket) &&
                    (await TicketListService.checkQueue(user, data.ticket))
                ) {
                    dispatch({
                        type: TicketActionType.UPDATE_TICKET_UNREAD_MESSAGES,
                        payload: data.ticket,
                    });
                }
            },
        );

        socket.on('contact', (data: { action: string; contact: any }) => {
            if (data.action === 'update') {
                dispatch({
                    type: TicketActionType.UPDATE_TICKET_CONTACT,
                    payload: data.contact,
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [status, searchParam, showAll, user, selectedQueueIds]);

    useEffect(() => {
        if (typeof updateCount === 'function') {
            updateCount(ticketsList.length);
        }
    }, [ticketsList, updateCount]);

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1);
    };

    const handleScroll = (e: {
        currentTarget: {
            scrollTop: any;
            scrollHeight?: any;
            clientHeight?: any;
        };
    }) => {
        if (!hasMore || loading) return;

        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            e.currentTarget.scrollTop = scrollTop - 100;
            loadMore();
        }
    };

    return (
        <Container className="ticketsListWrapper" style={style}>
            <Paper
                square
                elevation={0}
                className="ticketsList"
                onScroll={handleScroll}
            >
                <List style={{ paddingTop: 0 }}>
                    {ticketsList.length === 0 && !loading ? (
                        <div className="noTicketsDiv">
                            <span className="noTicketsTitle">
                                {Message.NO_TICKETS_TITLE}
                            </span>
                            <p className="noTicketsText">
                                {Message.NO_TICKETS_MESSAGE}
                            </p>
                        </div>
                    ) : (
                        <>
                            {ticketsList.map((ticket: { id: any }) => (
                                <TicketListItem
                                    ticket={ticket}
                                    key={ticket.id}
                                />
                            ))}
                        </>
                    )}
                    {loading && <TicketsListSkeleton />}
                </List>
            </Paper>
        </Container>
    );
}
