import { ListItem } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(ListItem)`
    position: relative;

    &.pendingTicket {
        cursor: unset;
    }

    .noTicketsDiv {
        display: flex;
        height: 100px;
        margin: 40px;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .noTicketsText {
            text-align: center;
            color: rgb(104, 121, 146);
            font-size: 14px;
            line-height: 1.4;
        }

        .noTicketsTitle {
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            margin: 0px;
        }
    }

    .contactNameWrapper {
        display: flex;
        justify-content: space-between;

        .closedBadge {
            align-self: center;
            justify-self: flex-end;
            margin-right: 32px;
            margin-left: auto;
        }

        .userTag {
            position: absolute;
            margin-right: 5px;
            right: 5px;
            bottom: 5px;
            background: #0079c1;
            color: #ffffff;
            border: 1px solid #ccc;
            padding: 1px;
            padding-left: 5px;
            padding-right: 5px;
            border-radius: 10px;
            font-size: 0.9em;
        }

        .contactLastMessage {
            padding-right: 20px;
            width: 259px !important;
            word-wrap: break-word;
            font-size: 13px;
            margin-top: 4px;
        }

        .newMessagesCount {
            align-self: center;
            margin-right: 8px;
            margin-left: auto;
        }

        .badgeStyle {
            color: white;
            background-color: #4caf50;
        }

        .box-button {
            display: flex;

            .acceptButton {
                width: 68px;
                height: 20px;
                font-size: 12px;
                margin-right: 10px;
            }

            .lastMessageTime {
                justify-self: flex-end;
            }
        }
    }

    &.pendingTicket .box-button {
        min-width: 204px;
        justify-content: space-between;
    }

    .ticketQueueColor {
        flex: none;
        width: 8px;
        height: 100%;
        position: absolute;
        top: 0%;
        left: 0%;
    }

    @media (max-width: 1366px) {
        .box-button {
            display: flex;

            .acceptButton {
                width: 48px;
                height: 18px;
                font-size: 10px;
                margin-right: 5px;
            }

            .lastMessageTime {
                justify-self: flex-end;
                font-size: 10px;
            }
        }

        &.pendingTicket .box-button {
            min-width: 175px;
            justify-content: space-between;
        }
    }
`;
