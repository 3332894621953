import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Button,
  CssBaseline,
  TextField,
  Container,
  InputAdornment,
  IconButton,
  Chip,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

import { i18n } from '../../translate/i18n';

import { AuthContext } from '../../context/Auth/AuthContext';

import logo from '../../assets/logo.svg';
import getEnvironment from '../../helpers/getEnviroment';
import getTextEnv from '../../helpers/getTexEnv';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  divLogo: {
    display: 'block',
    margin: 'auto',

    marginBottom: '30px',
    '& img': {
      maxWidth: '260px',
    },
  },
}));

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.divLogo}>
          <a href="https://triv.io" rel="noreferrer">
            <img src={logo} alt="logo" />
          </a>
        </div>
        {getEnvironment() !== 'production' && (
          <div>
            <Chip
              label={getTextEnv()}
              size="small"
              style={{ backgroundColor: 'rgb(227, 125, 61)', color: 'white' }}
            />
          </div>
        )}
        <form className={classes.form} noValidate onSubmit={handlSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={i18n.t('login.form.email')}
            name="email"
            value={user.email}
            onChange={handleChangeInput}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={i18n.t('login.form.password')}
            id="password"
            value={user.password}
            onChange={handleChangeInput}
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((e) => !e)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {i18n.t('login.buttons.submit')}
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default Login;
