import React, { useState, useEffect, useContext } from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';

import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import _ from 'lodash';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  CircularProgress,
  TextField,
  Switch,
  FormControlLabel,
} from '@material-ui/core';

import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import toastError from '../../errors/toastError';
import QueueSelect from '../QueueSelect';
import { AuthContext } from '../../context/Auth/AuthContext';
import getAdmin from '../../helpers/getAdmin';
import CookieService from '../../services/CookieService';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  multFieldLine: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: 'relative',
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, `${i18n.t('errors.minCharacters')}`)
    .max(50, `${i18n.t('errors.maxCharacters')}`)
    .required(`${i18n.t('errors.required')}`),
  greetingMessage: Yup.string().test(
    'check-greeting-message',
    `${i18n.t('errors.required')}`,
    function Validation(value) {
      const elements = document.getElementsByClassName('queues');

      if (elements.length > 1 && _.isEmpty(value)) {
        return false;
      }

      return true;
    }
  ),
  queues: Yup.mixed().test(
    'check-queues',
    `${i18n.t('errors.required')}`,
    function Validation(value) {
      const elements = document.getElementsByClassName('queues');

      if (elements.length === 0) {
        return false;
      }

      return true;
    }
  ),
  EconomicGroupId: Yup.string()
    .min(36, `${i18n.t('errors.invalidValue')}`)
    .max(36, `${i18n.t('errors.maxCharacters')}`)
    .test(
      'Check-economic-group',
      `${i18n.t('errors.required')}`,
      function Validation(value) {
        const jwt = CookieService.get('token');

        const token = jwt.split('.');

        const data = JSON.parse(atob(token[1]));

        if (_.isEmpty(value) && data.EconomicGroupId === getAdmin()) {
          return false;
        }

        return true;
      }
    ),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();
  const initialState = {
    name: '',
    greetingMessage: '',
    farewellMessage: '',
    isDefault: false,
    queues: false,
    EconomicGroupId: '',
  };
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}`);
        setWhatsApp(data);

        const whatsQueueIds = data.queues?.map((queue) => queue.id);
        setSelectedQueueIds(whatsQueueIds);
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  const handleSaveWhatsApp = async (values) => {
    const whatsappData = { ...values, queueIds: selectedQueueIds };
    try {
      if (selectedQueueIds.length === 0) {
        throw new Error('ERR_NO_QUEUE_WHATSAPP');
      }
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        await api.post('/whatsapp', whatsappData);
      }
      toast.success(i18n.t('whatsappModal.success'));
      handleClose();
    } catch (err) {
      if (err.message === 'ERR_NO_QUEUE_WHATSAPP')
        toast.error(i18n.t(`backendErrors.${err.message}`), {
          toastId: err.message,
        });
      else toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
  };

  const { user: loggedInUser } = useContext(AuthContext);

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle>
          {whatsAppId
            ? i18n.t('whatsappModal.title.edit')
            : i18n.t('whatsappModal.title.add')}
        </DialogTitle>
        <Formik
          initialValues={whatsApp}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveWhatsApp(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, touched, errors, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    label={i18n.t('whatsappModal.form.name')}
                    name="name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                  />
                  <FormControlLabel
                    control={
                      <Field
                        as={Switch}
                        color="primary"
                        name="isDefault"
                        checked={values.isDefault}
                      />
                    }
                    label={i18n.t('whatsappModal.form.default')}
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t('queueModal.form.greetingMessage')}
                    type="greetingMessage"
                    multiline
                    rows={5}
                    fullWidth
                    name="greetingMessage"
                    error={
                      touched.greetingMessage && Boolean(errors.greetingMessage)
                    }
                    helperText={
                      touched.greetingMessage && errors.greetingMessage
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t('whatsappModal.form.farewellMessage')}
                    type="farewellMessage"
                    multiline
                    rows={5}
                    fullWidth
                    name="farewellMessage"
                    error={
                      touched.farewellMessage && Boolean(errors.farewellMessage)
                    }
                    helperText={
                      touched.farewellMessage && errors.farewellMessage
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <QueueSelect
                  selectedQueueIds={selectedQueueIds}
                  className="queues"
                  onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                  name="queues"
                  id="queues"
                  error={touched.queues && Boolean(errors.queues)}
                  helperText={touched.queues && errors.queues}
                />
                <div>
                  {loggedInUser.EconomicGroupId === getAdmin() && (
                    <>
                      <Field
                        as={TextField}
                        label="EconomicGroupId"
                        pcon
                        name="EconomicGroupId"
                        error={
                          touched.EconomicGroupId &&
                          Boolean(errors.EconomicGroupId)
                        }
                        helperText={
                          touched.EconomicGroupId && errors.EconomicGroupId
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                      {/* <Field
                        as={TextField}
                        label="urlReceiveMsg"
                        pcon
                        name="urlReceiveMsg"
                        error={
                          touched.urlReceiveMsg && Boolean(errors.urlReceiveMsg)
                        }
                        helperText={
                          touched.urlReceiveMsg && errors.urlReceiveMsg
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                      <Field
                        as={TextField}
                        label="urlSendMsg"
                        pcon
                        name="urlSendMsg"
                        error={touched.urlSendMsg && Boolean(errors.urlSendMsg)}
                        helperText={touched.urlSendMsg && errors.urlSendMsg}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      /> */}
                    </>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t('whatsappModal.buttons.cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {whatsAppId
                    ? i18n.t('whatsappModal.buttons.okEdit')
                    : i18n.t('whatsappModal.buttons.okAdd')}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
