import { Paper } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Paper)`
    position: relative;
    display: flex;
    height: 100%;
    overflow: hidden;
    flex-direction: column;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    .ticketsList {
        flex: 1;
        overflow-y: scroll;
        border-top: 2px solid rgba(0, 0, 0, 0.12);

        &::-webkit-scrollbar {
            width: 6px !important;
        }

        &::-webkit-scrollbar-track {
            background: transparent !important;
        }

        &::-webkit-scrollbar-thumb {
            background: #0079c1 !important;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #0079c1 !important;
        }

        .noTicketsDiv {
            display: flex;
            height: 100px;
            margin: 40px;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .noTicketsTitle {
                text-align: center;
                font-size: 16px;
                font-weight: 600;
                margin: 0px;
            }

            .noTicketsText {
                text-align: center;
                color: rgb(104, 121, 146);
                font-size: 14px;
                line-height: 1.4;
            }
        }
    }

    .ticketsListHeader {
        color: rgb(67 83 105);
        z-index: 2;
        background-color: white;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .ticketsCount {
        font-weight: normal;
        color: rgb(104, 121, 146);
        margin-left: 8px;
        font-size: 14px;
    }
`;
