import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    format,
    isSameDay,
} from 'date-fns';
import { StatusType } from '../status/statusType';

export default class TicketItemService {
    static getDateDifference = (date: Date): string => {
        const referenceDate = new Date(date);

        const days = differenceInDays(new Date(), referenceDate);
        const hours = differenceInHours(new Date(), referenceDate) % 24;
        const minutes = differenceInMinutes(new Date(), referenceDate) % 60;

        if (days !== 0) return `${days}d ${hours}h ${minutes}m`;

        if (hours !== 0) return `${hours}h ${minutes}m`;

        return `${minutes}m`;
    };

    static getDateFormated = (date: Date): string => {
        const referenceDate = new Date(date);

        if (isSameDay(referenceDate, new Date()))
            return format(referenceDate, 'HH:mm');

        return format(referenceDate, 'dd/MM/yyyy');
    };

    static makeDateTicket = (status: StatusType, ticket: any): string => {
        switch (status) {
            case StatusType.OPEN:
                return `Atualizado: ${this.getDateDifference(ticket.updatedAt)}`;
            case StatusType.PENDING:
                return `Espera: ${this.getDateDifference(ticket.updatedAt)}`;
            default:
                return this.getDateFormated(ticket.updatedAt);
        }
    };
}
