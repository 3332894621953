import React, { useState, useEffect, useRef, useContext } from 'react';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { i18n } from '../../translate/i18n';

import api from '../../services/api';
import toastError from '../../errors/toastError';
import { AuthContext } from '../../context/Auth/AuthContext';
import getAdmin from '../../helpers/getAdmin';
import CookieService from '../../services/CookieService';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
  },
  textField: {
    marginRight: theme.spacing(1),
    width: '100%',
  },

  btnWrapper: {
    position: 'relative',
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: '100%',
  },
}));

const QuickAnswerSchema = Yup.object().shape({
  shortcut: Yup.string()
    .min(2, `${i18n.t('errors.minCharacters')}`)
    .max(15, `${i18n.t('errors.maxCharacters')}`)
    .required(`${i18n.t('errors.required')}`),
  message: Yup.string()
    .min(8, `${i18n.t('errors.minCharacters')}`)
    .max(30000, `${i18n.t('errors.maxCharacters')}`)
    .required(`${i18n.t('errors.required')}`),
  EconomicGroupId: Yup.string()
    .min(36, `${i18n.t('errors.invalidValue')}`)
    .max(36, `${i18n.t('errors.maxCharacters')}`)
    .test(
      'Check-economic-group',
      `${i18n.t('errors.required')}`,
      function Validation(value) {
        const jwt = CookieService.get('token');

        const token = jwt.split('.');

        const data = JSON.parse(atob(token[1]));

        if (_.isEmpty(value) && data.EconomicGroupId === getAdmin()) {
          return false;
        }

        return true;
      }
    ),
});

const QuickAnswersModal = ({
  open,
  onClose,
  quickAnswerId,
  initialValues,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);

  const initialState = {
    shortcut: '',
    message: '',
  };

  const [quickAnswer, setQuickAnswer] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchQuickAnswer = async () => {
      if (initialValues) {
        setQuickAnswer((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!quickAnswerId) return;

      try {
        const { data } = await api.get(`/quickAnswers/${quickAnswerId}`);
        if (isMounted.current) {
          setQuickAnswer(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchQuickAnswer();
  }, [quickAnswerId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setQuickAnswer(initialState);
  };

  const handleSaveQuickAnswer = async (values) => {
    try {
      if (quickAnswerId) {
        await api.put(`/quickAnswers/${quickAnswerId}`, values);
        handleClose();
      } else {
        const { data } = await api.post('/quickAnswers', values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t('quickAnswersModal.success'));
      window.location.reload();
    } catch (err) {
      toastError(err);
    }
  };

  const { user: loggedInUser } = useContext(AuthContext);

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
          {quickAnswerId
            ? `${i18n.t('quickAnswersModal.title.edit')}`
            : `${i18n.t('quickAnswersModal.title.add')}`}
        </DialogTitle>
        <Formik
          initialValues={quickAnswer}
          enableReinitialize={true}
          validationSchema={QuickAnswerSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveQuickAnswer(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t('quickAnswersModal.form.shortcut')}
                    name="shortcut"
                    autoFocus
                    error={touched.shortcut && Boolean(errors.shortcut)}
                    helperText={touched.shortcut && errors.shortcut}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    fullWidth
                  />
                </div>
                <div className={classes.textQuickAnswerContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t('quickAnswersModal.form.message')}
                    name="message"
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                    variant="outlined"
                    margin="dense"
                    className={classes.textField}
                    multiline
                    rows={5}
                    fullWidth
                  />
                </div>
                {loggedInUser.EconomicGroupId === getAdmin() && (
                  <Field
                    as={TextField}
                    label="EconomicGroupId"
                    autoFocus
                    name="EconomicGroupId"
                    error={
                      touched.EconomicGroupId && Boolean(errors.EconomicGroupId)
                    }
                    helperText={
                      touched.EconomicGroupId && errors.EconomicGroupId
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t('quickAnswersModal.buttons.cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {quickAnswerId
                    ? `${i18n.t('quickAnswersModal.buttons.okEdit')}`
                    : `${i18n.t('quickAnswersModal.buttons.okAdd')}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default QuickAnswersModal;
