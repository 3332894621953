import Cookies from 'universal-cookie';

export default class CookieService {
  static get(key) {
    const cookie = new Cookies();
    return cookie.get(key);
  }

  static set(key, value, options) {
    const cookie = new Cookies();
    cookie.set(key, value, options);
  }

  static remove(key, options) {
    const cookie = new Cookies();
    cookie.remove(key, options);
  }
}
