import React, { useState, useEffect, useRef, useContext } from 'react';

import * as Yup from 'yup';
import { Formik, Form, Field } from 'formik';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import { i18n } from '../../translate/i18n';

import api from '../../services/api';
import toastError from '../../errors/toastError';
import ColorPicker from '../ColorPicker';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Colorize } from '@material-ui/icons';
import { AuthContext } from '../../context/Auth/AuthContext';
import getAdmin from '../../helpers/getAdmin';
import CookieService from '../../services/CookieService';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  btnWrapper: {
    position: 'relative',
  },

  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
}));

const QueueSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, `${i18n.t('errors.minCharacters')}`)
    .max(50, `${i18n.t('errors.maxCharacters')}`)
    .required(`${i18n.t('errors.required')}`),
  color: Yup.string()
    .min(3, `${i18n.t('errors.minCharacters')}`)
    .max(9, `${i18n.t('errors.maxCharacters')}`)
    .required(`${i18n.t('errors.required')}`),
  greetingMessage: Yup.string(),
  EconomicGroupId: Yup.string()
    .min(36, `${i18n.t('errors.invalidValue')}`)
    .max(36, `${i18n.t('errors.maxCharacters')}`)
    .test(
      'Check-economic-group',
      `${i18n.t('errors.required')}`,
      function Validation(value) {
        const jwt = CookieService.get('token');

        const token = jwt.split('.');

        const data = JSON.parse(atob(token[1]));

        if (_.isEmpty(value) && data.EconomicGroupId === getAdmin()) {
          return false;
        }

        return true;
      }
    ),
});

const QueueModal = ({ open, onClose, queueId }) => {
  const classes = useStyles();

  const initialState = {
    name: '',
    color: '',
    greetingMessage: '',
    EconomicGroupId: '',
  };

  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [queue, setQueue] = useState(initialState);
  const greetingRef = useRef();
  const { user: loggedInUser } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      if (!queueId) return;
      try {
        const { data } = await api.get(`/queue/${queueId}`);
        setQueue((prevState) => {
          return { ...prevState, ...data };
        });
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {
      setQueue({
        name: '',
        color: '',
        greetingMessage: '',
        EconomicGroupId: '',
      });
    };
  }, [queueId, open]);

  const handleClose = () => {
    onClose();
    setQueue(initialState);
  };

  const handleSaveQueue = async (values) => {
    try {
      if (queueId) {
        await api.put(`/queue/${queueId}`, values);
      } else {
        await api.post('/queue', values);
      }
      toast.success(`${i18n.t('success')}`);
      handleClose();
      window.location.reload();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle>
          {queueId
            ? `${i18n.t('queueModal.title.edit')}`
            : `${i18n.t('queueModal.title.add')}`}
        </DialogTitle>
        <Formik
          initialValues={queue}
          enableReinitialize={true}
          validationSchema={QueueSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveQueue(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values }) => (
            <Form>
              <DialogContent dividers>
                <Field
                  as={TextField}
                  label={i18n.t('queueModal.form.name')}
                  autoFocus
                  name="name"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  variant="outlined"
                  margin="dense"
                  className={classes.textField}
                />
                <Field
                  as={TextField}
                  label={i18n.t('queueModal.form.color')}
                  name="color"
                  id="color"
                  onFocus={() => {
                    setColorPickerModalOpen(true);
                    greetingRef.current.focus();
                  }}
                  error={touched.color && Boolean(errors.color)}
                  helperText={touched.color && errors.color}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div
                          style={{ backgroundColor: values.color }}
                          className={classes.colorAdorment}
                        ></div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        size="small"
                        color="default"
                        onClick={() => setColorPickerModalOpen(true)}
                      >
                        <Colorize />
                      </IconButton>
                    ),
                  }}
                  variant="outlined"
                  margin="dense"
                />
                <ColorPicker
                  open={colorPickerModalOpen}
                  handleClose={() => setColorPickerModalOpen(false)}
                  onChange={(color) => {
                    values.color = color;
                    setQueue(() => {
                      return { ...values, color };
                    });
                  }}
                />
                <div>
                  <Field
                    as={TextField}
                    label={i18n.t('queueModal.form.greetingMessage')}
                    type="greetingMessage"
                    multiline
                    inputRef={greetingRef}
                    rows={5}
                    fullWidth
                    name="greetingMessage"
                    error={
                      touched.greetingMessage && Boolean(errors.greetingMessage)
                    }
                    helperText={
                      touched.greetingMessage && errors.greetingMessage
                    }
                    variant="outlined"
                    margin="dense"
                  />
                </div>
                <div>
                  {loggedInUser.EconomicGroupId === getAdmin() && (
                    <Field
                      as={TextField}
                      label="EconomicGroupId"
                      name="EconomicGroupId"
                      error={
                        touched.EconomicGroupId &&
                        Boolean(errors.EconomicGroupId)
                      }
                      helperText={
                        touched.EconomicGroupId && errors.EconomicGroupId
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t('queueModal.buttons.cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {queueId
                    ? `${i18n.t('queueModal.buttons.okEdit')}`
                    : `${i18n.t('queueModal.buttons.okAdd')}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default QueueModal;
