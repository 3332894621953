import { toast } from 'react-toastify';

const toastError = (err) => {
  const errorMsg = err.response?.data?.message || err.response?.data?.error;
  if (errorMsg) {
    toast.error(errorMsg, {
      toastId: errorMsg,
    });
  } else {
    toast.error('Ocorreu um erro inesperado, por favor tente novamente');
  }
};

export default toastError;
