import { StatusType } from '../status/statusType';
import { TicketActionType } from './actionType';

export default class TicketListService {
    static async checkQueue(user: { queues: any[] }, msg: { queueId: any }) {
        let response = false;
        await user.queues.forEach((element: { id: any }) => {
            if (element.id === msg.queueId) {
                response = true;
            }
        });
        return response;
    }

    static reducer(
        state: any[],
        action: { type: TicketActionType; payload: any },
    ): any {
        if (action.type === TicketActionType.LOAD_TICKETS) {
            const newTickets = action.payload;

            newTickets.forEach(
                (ticket: { id: any; unreadMessages: number }) => {
                    const ticketIndex = state.findIndex(
                        (t: { id: any }) => t.id === ticket.id,
                    );
                    if (ticketIndex !== -1) {
                        state[ticketIndex] = ticket;
                        if (ticket.unreadMessages > 0) {
                            state.unshift(state.splice(ticketIndex, 1)[0]);
                        }
                    } else {
                        state.push(ticket);
                    }
                },
            );

            return [...state];
        }

        if (action.type === TicketActionType.RESET_UNREAD) {
            const ticketId = action.payload;

            const ticketIndex = state.findIndex(
                (t: { id: any }) => t.id === ticketId,
            );
            if (ticketIndex !== -1) {
                state[ticketIndex].unreadMessages = 0;
            }

            return [...state];
        }

        if (action.type === TicketActionType.UPDATE_TICKET) {
            const ticket = action.payload;

            const ticketIndex = state.findIndex(
                (t: { id: any }) => t.id === ticket.id,
            );
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
            } else {
                ticket.status === StatusType.PENDING
                    ? state.push(ticket)
                    : state.unshift(ticket);
            }

            return [...state];
        }

        if (action.type === TicketActionType.UPDATE_TICKET_UNREAD_MESSAGES) {
            const ticket = action.payload;

            const ticketIndex = state.findIndex(
                (t: { id: any }) => t.id === ticket.id,
            );
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
                ticket.status === StatusType.PENDING
                    ? state.push(state.splice(ticketIndex, 1)[0])
                    : state.unshift(state.splice(ticketIndex, 1)[0]);
            } else {
                ticket.status === StatusType.PENDING
                    ? state.push(ticket)
                    : state.unshift(ticket);
            }

            return [...state];
        }

        if (action.type === TicketActionType.UPDATE_TICKET_CONTACT) {
            const contact = action.payload;
            const ticketIndex = state.findIndex(
                (t: { contactId: any }) => t.contactId === contact.id,
            );
            if (ticketIndex !== -1) {
                state[ticketIndex].contact = contact;
            }
            return [...state];
        }

        if (action.type === TicketActionType.DELETE_TICKET) {
            const ticketId = action.payload;
            const ticketIndex = state.findIndex(
                (t: { id: any }) => t.id === ticketId,
            );
            if (ticketIndex !== -1) {
                state.splice(ticketIndex, 1);
            }

            return [...state];
        }

        if (action.type === TicketActionType.RESET) {
            return [];
        }
    }
}
