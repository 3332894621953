import React, { useState, useEffect, useRef, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import { i18n } from '../../translate/i18n';
import api from '../../services/api';
import ButtonWithSpinner from '../ButtonWithSpinner';
import MarkdownWrapper from '../MarkdownWrapper';
import { Tooltip } from '@material-ui/core';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import { Container } from './styles';
import TicketItemService from '../../services/tickets/ticketItemService';

interface TicketItemProps {
    ticket: any;
}

const TicketListItem: React.FC<TicketItemProps> = props => {
    const { ticket } = props;
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const ticketId = useParams();
    const isMounted = useRef(true);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleAcepptTicket = async (id: any) => {
        setLoading(true);
        try {
            await api.put(`/tickets/${id}`, {
                status: 'open',
                userId: user?.id,
            });
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
        if (isMounted.current) {
            setLoading(false);
        }
        history.push(`/tickets/${id}`);
    };

    const handleSelectTicket = (id: any) => {
        history.push(`/tickets/${id}`);
    };

    return (
        <>
            <Container
                dense
                button
                onClick={e => {
                    if (ticket.status === 'pending') return;
                    handleSelectTicket(ticket.id);
                }}
                selected={ticketId && +ticketId === ticket.id}
                className={clsx(ticket, {
                    pendingTicket: ticket.status === 'pending',
                })}
            >
                <Tooltip
                    arrow
                    placement="right"
                    title={ticket.queue?.name || 'Sem fila'}
                >
                    <span
                        style={{
                            backgroundColor: ticket.queue?.color || '#7C7C7C',
                        }}
                        className="ticketQueueColor"
                    ></span>
                </Tooltip>
                <ListItemAvatar>
                    <Avatar src={ticket?.contact?.profilePicUrl} />
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <span className="contactNameWrapper">
                            <Typography
                                noWrap
                                component="span"
                                variant="body2"
                                color="textPrimary"
                            >
                                {ticket.contact.name}
                            </Typography>
                            {ticket.status === 'closed' && (
                                <Badge
                                    className="closedBadge"
                                    badgeContent={'Fechado'}
                                    color="primary"
                                />
                            )}
                            <div className="box-button">
                                {ticket.status === 'pending' && (
                                    <ButtonWithSpinner
                                        color="primary"
                                        variant="contained"
                                        className="acceptButton"
                                        size="small"
                                        loading={loading}
                                        onClick={(e: any) =>
                                            handleAcepptTicket(ticket.id)
                                        }
                                    >
                                        {i18n.t('ticketsList.buttons.accept')}
                                    </ButtonWithSpinner>
                                )}
                                {ticket.lastMessage && (
                                    <Typography
                                        className="lastMessageTime"
                                        component="span"
                                        variant="body2"
                                        color="textSecondary"
                                    >
                                        {TicketItemService.makeDateTicket(
                                            ticket.status,
                                            ticket,
                                        )}
                                    </Typography>
                                )}
                            </div>
                            {ticket.whatsappId && (
                                <div
                                    className="userTag"
                                    title={i18n.t(
                                        'ticketsList.connectionTitle',
                                    )}
                                >
                                    {ticket.whatsapp?.name}
                                </div>
                            )}
                        </span>
                    }
                    secondary={
                        <span className="contactNameWrapper">
                            <Typography
                                className="contactLastMessage"
                                noWrap
                                component="span"
                                variant="body2"
                                color="textSecondary"
                                title={ticket.lastMessage}
                            >
                                {ticket.lastMessage ? (
                                    <MarkdownWrapper>
                                        {ticket.lastMessage}
                                    </MarkdownWrapper>
                                ) : (
                                    <br />
                                )}
                            </Typography>

                            <Badge
                                className="newMessagesCount"
                                badgeContent={ticket.unreadMessages}
                                classes={{
                                    badge: 'badgeStyle',
                                }}
                            />
                        </span>
                    }
                />
            </Container>
            <Divider variant="inset" component="li" />
        </>
    );
};

export default TicketListItem;
